'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;

  width: 100%;
  margin-left: auto;
  margin-right: auto;

  & > * {
    width: 100%;
    align-items: stretch;
  }
`;

export const CenteredStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
