// 'use client';
import React from 'react';

import * as Styled from './styled';
import buttonTheme from './buttonTheme';

import { Primary, Text, Cta, PrimaryOutline, Black, Secondary, Basic, Magic } from '../Button2';

export type TButtonVariant = 'primary' | 'basic' | 'secondary' | 'text' | 'cta' | 'unstyled';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  theme?: any;
  variant?: TButtonVariant;
  disabled?: boolean;
  rounded?: boolean;
  stretch?: boolean;
  small?: boolean;
  inline?: boolean;
  nowrap?: boolean;
  className?: string;
  testId?: string;
}

/** @deprecated */
const Button: React.FC<ButtonProps> = ({
  children = 'Default',
  variant = 'primary',
  disabled,
  rounded,
  stretch,
  small = false,
  inline = false,
  nowrap,
  theme = {},
  className,
  testId,
  ...props
}) => {
  // const mergedTheme = { ...theme, ...buttonTheme };
  const mergedTheme = buttonTheme;
  const dataTestId = testId ? `button-${testId}` : undefined;
  return (
    <Styled.Button
      data-testid={dataTestId}
      disabled={disabled}
      $stretch={stretch}
      $rounded={rounded}
      $variant={variant}
      theme={mergedTheme}
      $small={small}
      $inline={inline}
      $nowrap={nowrap}
      className={className}
      {...props}
    >
      {children}
    </Styled.Button>
  );
};

export default Object.assign(Button, {
  Black,
  Primary,
  Secondary,
  Text,
  Cta,
  PrimaryOutline,
  Basic,
  Magic,
});
