const getBool = (environmentVariable?: string) => {
  return (environmentVariable == 'true' || environmentVariable == '1')
}

const getString = (environmentVariable?: string) => {
  return environmentVariable || '';
}
// ------- Public environment variables -------
export const BASE_API_URL = getString(process.env.NEXT_PUBLIC_BASE_API_URL);
export const BASE_WEBAPP_URL = getString(process.env.NEXT_PUBLIC_BASE_WEBAPP_URL);
export const CLAIM_ORGANIZATION_URL = getString(process.env.NEXT_PUBLIC_CLAIM_ORGANIZATION_URL);
export const GIT_HUB_CLIENT_ID = getString(process.env.NEXT_PUBLIC_GITHUB_CLIENT_ID);
export const GOOGLE_ANALYTICS_ID = getString(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID);
export const GOOGLE_CLIENT_ID = getString(process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID);
export const IS_RUNNING_ON_PREMISES = getBool(process.env.NEXT_PUBLIC_OXEN_ON_PREMISES);
export const POSTHOG_HOST = getString(process.env.NEXT_PUBLIC_POSTHOG_HOST);
export const POSTHOG_KEY = getString(process.env.NEXT_PUBLIC_POSTHOG_KEY);
export const SANITY_DATASET = getString(process.env.NEXT_PUBLIC_SANITY_DATASET);
export const SANITY_PROJECT_ID = getString(process.env.NEXT_PUBLIC_SANITY_PROJECT_ID);
export const STRIPE_PUBLISHABLE_KEY = getString(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
// ------- Public environment variables -------

// ------- Private environment variables -------
export const GHOST_ADMIN_API_KEY = getString(process.env.NEXT_GHOST_ADMIN_API_KEY);
export const GHOST_CONTENT_API_KEY = getString(process.env.NEXT_GHOST_CONTENT_API_KEY);
export const GHOST_CONTENT_API_URL = getString(process.env.NEXT_GHOST_CONTENT_API_URL);
export const IS_IN_MAINTENANCE_MODE = getBool(process.env.MAINTENANCE_MODE);
export const NODE_ENV = getString(process.env.NODE_ENV);
export const STRIPE_SECRET_KEY = getString(process.env.STRIPE_SECRET_KEY);
// ------- Private environment variables -------
